<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="d-flex flex-wrap align-start" style="gap: 16px">
        <v-sheet width="100%" class="d-flex align-center pa-0">
          <v-tabs
            class="flex-grow-0"
            :show-arrows="false"
            dense
            v-model="impTab"
          >
            <v-tab>Impagados Actuales</v-tab>
            <v-tab>Histórico Impagados</v-tab>
          </v-tabs>
          <v-spacer></v-spacer>
          <!-- {{ periodo }} -->
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                class="align-center mr-2"
                small
                v-on="on"
                v-bind="attrs"
                color="primary"
              >
                <v-icon small left>mdi-update</v-icon>Incremento respecto a
                <v-icon>mdi-dropdown</v-icon>
              </v-btn>
            </template>
            <SelectorPeriodos v-model="periodo" />
          </v-menu>
        </v-sheet>
      </v-col>
      <v-col cols="12" class="d-flex flex-wrap align-start" style="gap: 16px">

        <tarjeta-top
          v-for="topCard in tarjetasTop"
          :key="topCard.texto"
          :loading="topCard.value === null"
          :value="topCard.value"
          :unit="topCard.unit"
          :texto="topCard.texto"
          :icon="topCard.icon"
          :increment="topCard.increment"
          :increment-way="topCard.incrementWay"
        />
        <!-- <v-spacer></v-spacer> -->
      </v-col>

      <v-col cols="12" class="d-flex">
        <v-card class="pa-2 d-flex flex-wrap" style="gap: 10px">
          <MontoPorEmpresa
            v-for="portal in estadisticas.deudaPorPortal"
            :key="portal.portal"
            :empresa="portal.portal"
            :importe="portal.deuda"
            :n-facturas="portal.nFacturas"
          />
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="6" xl="6">
        <DeudaPorCliente
          :value="estadisticas.deudaPorCliente"
          @exportar="(v, k) => exportar(v, k)"
          :alert-info="!impTab"
          title="Deuda por cliente reclamable"
        />
      </v-col>
      <v-col cols="12" sm="6" md="6" xl="6">
        <DeudaPorCliente
          :value="estadisticas.deudaPorClienteMonitorio"
          @exportar="(v, k) => exportar(v, k)"
          :alert-info="!impTab"
          title="Deuda por cliente no reclamable"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="3">
        <DeudaPorCanal
          :value="estadisticas.deudaPorCanal"
          :total-impagados="Number(tarjetasTop.totalImpagadosTotal.value)"
          @exportar="(v, k) => exportar(v, k)"
          :alert-info="!impTab"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="3">
        <ContratosBajaDeuda
          :value="estadisticas.contratosBajaDeuda"
          @exportar="(v, k) => exportar(v, k)"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="3">
        <ClientesImpagadosPorCanal
          :value="estadisticas.clientesImpagadosPorCanal"
          @exportar="(v, k) => exportar(v, k)"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="3">
        <ClientesComprometidosPago
          :value="estadisticas.clientesComprometidosPago"
          @exportar="(v, k) => exportar(v, k)"
        />
      </v-col>
      <!-- <v-col cols="12" sm="6" md="4" xl="3">
        <PendienteRemesarPorCliente
          :value="estadisticas.pendienteRemesarPorCliente"
          @exportar="(v, k) => exportar(v, k)"
        />
      </v-col>-->
      <!-- <v-col cols="12" sm="6" md="4" xl="3">
        <AbonosPendientesPorCliente
          :value="estadisticas.abonosPendientesPorCliente"
          @exportar="(v, k) => exportar(v, k)"
        />
      </v-col>-->
      <v-col cols="12" sm="12" md="8" xl="6">
        <ImpagadosPorCanalGrafico
          :key="yearChart + impTab"
          :value="lineaImpagadosPorCanal"
          :yearChart="yearChart"
          @yearChange="(v) => (yearChart = v)"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="3">
        <PorcentajeNImpagadosCanal
          :key="impTab"
          :value="porcentajeImpagadosPorCanal"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="3">
        <PorcentajeEURImpagadosCanal
          :key="impTab"
          :value="porcentajeEURImpagadosPorCanal"
        />
      </v-col>
      <v-col cols="12" md="12">
        <ListadoMensajes
          :listado-mensajes="listadoMensajes"
          :value="detailsId"
          :_tab="tab"
          @detailsId="(v) => (detailsId = v)"
          @tab="(v) => (tab = v)"
        />
      </v-col>
    </v-row>

    <v-dialog
      :value="detailsId"
      @input="(v) => (v === false ? (detailsId = null) : null)"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dense color="primary">
          <v-toolbar-title class="white--text"
            >Detalles impago Cod {{ detailsId }}</v-toolbar-title
          >
          <v-spacer></v-spacer>

          <v-btn color="white" icon @click="detailsId = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-tabs v-model="tab">
          <v-tab v-if="$root.acceso('CONTROL_IMPAGADOS')">Control impago</v-tab>
          <!-- <v-tab v-if="$root.acceso('CONTROL_IMPAGADOS')">Histórico llamadas</v-tab> -->
          <v-tab>Detalles factura</v-tab>
          <v-tab v-if="$root.acceso('CONTROL_IMPAGADOS')"
            >Avisos formales</v-tab
          >
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <detalles-impagados
              @changeIdCarteraCobro="(v) => (detailsId = v)"
              @close="detailsId = null"
              :IdCarteraCobro="detailsId"
              v-if="detailsId"
              :key="detailsId"
            />
          </v-tab-item>
          <!-- <v-tab-item>
						<historico-llamadas
							@close="detailsId = null"
							:IdCarteraCobro="detailsId"
							v-if="detailsId"
							:key="detailsId"
						/>
          </v-tab-item>-->
          <v-tab-item>
            <detalles-factura
              @close="detailsId = null"
              :idFactura="idCabeceraAux"
              :IdCarteraCobro="detailsId"
              v-if="idCabeceraAux"
              :key="idCabeceraAux"
            />
          </v-tab-item>
          <v-tab-item>
            <avisos-formales
              @close="detailsId = null"
              :IdCarteraCobro="detailsId"
              v-if="idCabeceraAux"
              :key="idCabeceraAux"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>

    <LoadingSteps :hide-overlay="false" v-if="checksPanel" :value="checks" />
  </v-container>
</template>

<script>
import { parseDate, downloadFile, perColumnFilter } from "@/utils/index.js";
import { parseImpagados } from "../services/parseImpagados";
import palette from "../services/colorPalette.js";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    TarjetaTop: () => import("@/components/TarjetaTop.vue"),
    LoadingSteps: () => import("@/components/LoadingSteps.vue"),
    SelectorPeriodos: () => import("../components/SelectorPeriodos.vue"),

    DetallesFactura: () =>
      import("@/modules/facturacion/views/DetallesFactura.vue"),
    DetallesImpagados: () => import("./DetallesImpagados.vue"),
    HistoricoLlamadas: () => import("./HistoricoLlamadas.vue"),
    AvisosFormales: () => import("./AvisosFormales.vue"),

    DeudaPorCliente: () => import("../components/DeudaPorCliente.vue"),
    ClientesComprometidosPago: () =>
      import("../components/ClientesComprometidosPago.vue"),
    DeudaPorCanal: () => import("../components/DeudaPorCanal.vue"),
    ContratosBajaDeuda: () => import("../components/ContratosBajaDeuda.vue"),
    ImpagadosPorCanalGrafico: () =>
      import("../components/ImpagadosPorCanalGrafico.vue"),
    PorcentajeNImpagadosCanal: () =>
      import("../components/PorcentajeNImpagadosCanal.vue"),
    PorcentajeEURImpagadosCanal: () =>
      import("../components/PorcentajeEURImpagadosCanal.vue"),
    ClientesImpagadosPorCanal: () =>
      import("../components/ClientesImpagadosPorCanal.vue"),
    ListadoMensajes: () => import("../components/ListadoMensajes.vue"),
    PendienteRemesarPorCliente: () =>
      import("../components/PendienteRemesarPorCliente.vue"),
    AbonosPendientesPorCliente: () =>
      import("../components/AbonosPendientesPorCliente.vue"),

    MontoPorEmpresa: () => import("../components/MontoPorEmpresa.vue"),
  },
  data() {
    return {
      loading: false,
      impagados: [],
      facturas: [],
      checksPanel: true,
      checks: {},

      periodo: Date.now() - 1000 * 3600 * 24 * 7,

      pdfId: null,
      detailsId: null,
      tab: 0,

      listadoMensajes: [],
      listadoMensajesFilters: {},
      listadoMensajesHeaders: [
        { text: "Método", value: "tipo", dataType: "select" },
        { text: "Fecha mensaje", value: "fecha", dataType: "date" },
        { text: "Mensaje", value: "mensaje" },
        { text: "Identificador", value: "Identificador" },
        { text: "Cliente", value: "denominacion" },
        { text: "Contrato", value: "CodigoContrato" },
        { text: "Factura", value: "NumeroFactura" },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
          filterable: false,
        },
      ].map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-2 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.listadoMensajesFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),

      impTab: 0,
      porcentajes: {},
      yearChart: 2021,
      // yearChart: new Date().getFullYear(),

      busquedas: {
        cliente: null,
        canal: null,
        bajaDeudas: null,
        clientesImpagadosPorCanal: null,
        pendienteRemesarPorCliente: null,
      },
      tarjetasTop: {
        clientesConImpagos: { 
          icon: "mdi-account-group",
          texto : 'Clientes con impagos',
          unit: '',
          value: null,
         },
        totalImpagadosTotal: { 
          icon: "mdi-currency-eur-off",
          texto : 'Total monto impagos',
          unit: '€',
          value: null,
         },
        gestionPropia: { 
          icon: "mdi-account-tie",
          texto : 'Gestión propia',
          unit: '€',
          value: null,
         },
        gestionGesico: { 
          icon: "mdi-police-badge-outline",
          texto : 'Gestión Gesico',
          unit: '€',
          value: null,
         },
        gestionCesce: { 
          icon: "mdi-sale",
          texto : 'Gestión Cesce',
          unit: '€',
          value: null,
         },
        gestionMonitorio: { 
          icon: "mdi-gavel",
          texto : 'Gestión Monitorio',
          unit: '€',
          value: null,
         },
        estadoPerdidas: { 
          icon: "mdi-delete",
          texto : 'Monto en pérdidas',
          unit: '€',
          value: null,
         },
        pendienteRemesar: { 
          icon: "mdi-bank",
          texto : 'Pendiente remesar',
          unit: '€',
          value: null,
         },
      },
      // tarjetasTop: {
      //   numeroImpagados: {
      //     icon: "mdi-currency-eur-off",
      //     texto: "Facturas Impagadas",
      //     value: null,
      //     unit: "",
      //     acceso: "CONTROL_IMPAGADOS",
      //     incrementWay: "down",
      //   },
      //   clientesImpagados: {
      //     icon: "mdi-account-alert",
      //     texto: "Clientes con impagos",
      //     value: null,
      //     unit: "",
      //     acceso: "CONTROL_IMPAGADOS",
      //     incrementWay: "down",
      //   },
      //   totalImpagadosTotal: {
      //     icon: "mdi-currency-eur-off",
      //     texto: "Monto Impagados Total",
      //     value: null,
      //     unit: "€",
      //     acceso: "CONTROL_IMPAGADOS",
      //     historico: false,
      //     incrementWay: "down",
      //   },
      //   pagoNoAcordado: {
      //     icon: "mdi-currency-eur-off",
      //     texto: "Pago no acordado",
      //     value: null,
      //     unit: "€",
      //     acceso: "CONTROL_IMPAGADOS",
      //     historico: false,
      //     incrementWay: "down",
      //   },
      //   totalComprometidoPago: {
      //     icon: "mdi-handshake",
      //     texto: "Pago Comprometido",
      //     value: null,
      //     unit: "€",
      //     acceso: "CONTROL_IMPAGADOS",
      //     historico: false,
      //     incrementWay: "up",
      //   },
      //   totalRecuperado: {
      //     icon: "mdi-cash",
      //     texto: "Monto Recuperado",
      //     value: null,
      //     unit: "€",
      //     acceso: "CONTROL_IMPAGADOS",
      //     historico: true,
      //     incrementWay: "up",
      //   },
      //   pendienteRemesar: {
      //     icon: "mdi-bank",
      //     texto: "Pendiente de remesar",
      //     value: null,
      //     unit: "€",
      //     acceso: "CONTROL_IMPAGADOS",
      //     historico: false,
      //     incrementWay: "down",
      //   },
      //   totalPerdidas: {
      //     icon: "mdi-delete",
      //     texto: "Pérdidas",
      //     value: null,
      //     unit: "€",
      //     acceso: "CONTROL_IMPAGADOS",
      //     historico: false,
      //     incrementWay: "down",
      //   },
      //   totalClientesBajaDeuda: {
      //     icon: "mdi-cash-remove",
      //     texto: "Clientes Baja con Deuda",
      //     value: null,
      //     unit: "",
      //     acceso: "CONTROL_IMPAGADOS",
      //     incrementWay: "down",
      //   },
      // },
    };
  },
  computed: {
    idCabeceraAux() {
      return (
        this.impagados.find((x) => x.IdCarteraCobro == this.detailsId) || {}
      ).IdFacturaVentaCabecera;
    },
    impagadosFiltrados() {
      return this.impagados.filter((imp) => imp.historico == this.impTab);
    },
    porcentajeImpagadosPorCanal() {
      if (this.impagadosFiltrados.length == 0) return null;
      if (
        !this.estadisticas.deudaPorCanal ||
        this.estadisticas.deudaPorCanal.length == 0
      )
        return null;

      let results = {
        labels: [],
        datasets: [
          {
            data: [],
            hoverBackgroundColor: [],
            backgroundColor: [],
          },
        ],
      };

      const stringToColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = "#";
        for (let i = 0; i < 3; i++) {
          let value = (hash >> (i * 8)) & 0xff;
          color += ("00" + value.toString(16)).substr(-2);
        }
        return color;
      };

      let canales = this.estadisticas.deudaPorCanal
        .map(({ CanalAgente: canal, nImpagados, IdAgente }) => ({
          canal,
          nImpagados,
          IdAgente,
        }))
        .sort((a, b) => Number(b.deuda) - Number(a.deuda));

      canales.forEach(({ canal, nImpagados, IdAgente }) => {
        results.labels.push(canal);
        results.datasets[0].data.push(nImpagados);
        const color =
          palette[
            ((IdAgente % palette.length) + palette.length) % palette.length
          ];
        results.datasets[0].hoverBackgroundColor.push(color);
        results.datasets[0].backgroundColor.push(color + "a0");
      });

      return results;
    },
    porcentajeEURImpagadosPorCanal() {
      if (this.impagadosFiltrados.length == 0) return null;
      if (
        !this.estadisticas.deudaPorCanal ||
        this.estadisticas.deudaPorCanal.length == 0
      )
        return null;
      // let canales = [...new Set(this.impagados.map(i => i.CanalAgente))];

      let results = {
        labels: [],
        datasets: [
          {
            data: [],
            hoverBackgroundColor: [],
            backgroundColor: [],
          },
        ],
      };

      // console.log(this.estadisticas.deudaPorCanal)

      const stringToColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = "#";
        for (let i = 0; i < 3; i++) {
          let value = (hash >> (i * 8)) & 0xff;
          color += ("00" + value.toString(16)).substr(-2);
        }
        return color;
      };

      let canales = this.estadisticas.deudaPorCanal
        .map(({ CanalAgente: canal, deuda, IdAgente }) => ({
          canal,
          deuda: parseFloat(deuda).toFixed(2),
          IdAgente,
        }))
        .sort((a, b) => Number(b.deuda) - Number(a.deuda));

      // canales = canales.map(canal => ({ canal, nImpagados: parseFloat(this.impagadosFiltrados.filter(imp => imp.CanalAgente == canal).reduce((a, c) => Number(c.ImporteTotal) + a, 0)).toFixed(2) })).sort((a, b) => Number(a.nImpagados) > Number(b.nImpagados) ? -1 : 1)

      canales.forEach(({ canal, deuda, IdAgente }) => {
        results.labels.push(canal);
        results.datasets[0].data.push(deuda);
        const color =
          palette[
            ((IdAgente % palette.length) + palette.length) % palette.length
          ];
        results.datasets[0].hoverBackgroundColor.push(color);
        results.datasets[0].backgroundColor.push(color + "a0");
      });

      return results;
    },
    lineaImpagadosPorCanal() {
      if (this.impagadosFiltrados.length == 0) return null;
      // let canales = [...new Set(this.impagadosFiltrados.map(i => ({canal: i.CanalAgente, IdAgente: i.IdAgente})))];
      let canales = Object.values(
        this.impagadosFiltrados.reduce((r, a) => {
          r[a.CanalAgente] = r[a.CanalAgente] || {
            canal: a.CanalAgente,
            IdAgente: a.IdAgente,
          };
          return r;
        }, Object.create(null))
      );
      const years = [
        ...new Set(
          this.impagadosFiltrados.map((i) =>
            i.FechaImpagado
              ? Number(new Date(i.FechaImpagado).getFullYear())
              : null
          )
        ),
      ]
        .filter((x) => !!x)
        .sort();

      let results = {
        labels: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        datasets: [],
      };

      const stringToColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = "#";
        for (let i = 0; i < 3; i++) {
          let value = (hash >> (i * 8)) & 0xff;
          color += ("00" + value.toString(16)).substr(-2);
        }
        return color;
      };

      canales.forEach(({ canal, IdAgente }, k) => {
        const impagadosMes = [
          ...Array(12)
            .fill(0)
            .keys(),
        ].map(
          (mes) =>
            this.impagadosFiltrados.filter(
              (imp) =>
                imp.FechaImpagado &&
                imp.CanalAgente == canal &&
                new Date(imp.FechaImpagado).getMonth() == mes &&
                new Date(imp.FechaImpagado).getFullYear() == this.yearChart
            ).length
        );
        const color =
          palette[
            ((IdAgente % palette.length) + palette.length) % palette.length
          ];

        if (impagadosMes.every((mes) => mes == 0)) {
          // results.datasets[k] = {}
          return;
        }

        results.datasets.push({
          label: canal,
          fill: false,
          borderColor: color + "a0",
          data: impagadosMes,
          hoverBackgroundColor: color,
          backgroundColor: color + "a0",
          order: 1,
        });
      });

      return { results, years };
    },

    estadisticas() {
      if (!this.facturas || this.facturas.length == 0) return {};
      // const canales = [...new Set(this.facturas.map(i => i.CanalAgente.replace(/\r\n/, '')))];
      // const codBaja = [...new Set(this.impagadosFiltrados.filter(imp => imp.situacionContrato == 'Baja').map(imp => imp.CodigoContrato))];

      const sumarDeuda = (a, b) => {
        let importe = parseFloat(Number(b.ImporteTotal)).toFixed(2) * 1;

        if (!this.impTab) {
          if (b.futuro && b.SerieFactura == "FRAC")
            a.deudaFra = (importe + a.deudaFra).toFixed(2) * 1;
          else if (b.aplazado)
            a.deudaApl = (importe + a.deudaApl).toFixed(2) * 1;
          else if (b.estado == "Impagado") {
            a.deudaImp = (importe + a.deudaImp).toFixed(2) * 1;
          }
        } else if (b.historico) {
          a.deudaRec = (importe + a.deudaRec).toFixed(2) * 1;
        }

        a.deuda =
          parseFloat(
            a.deudaFra * 1 + a.deudaApl * 1 + a.deudaImp * 1 + a.deudaRec * 1
          ).toFixed(2) * 1;
        return a;
      };

      const impApl = [
        ...this.impagadosFiltrados,
        ...this.facturas.filter((f) => f.futuro || f.aplazado),
      ];

      const deudaPorCliente = Object.values(
        impApl
          .filter((c) =>
            [
              c.dptoJuridico,
              c.CESCE_siniestro,
              c.situacionContrato == "Baja",
            ].every((x) => !x)
          )
          .reduce((r, a) => {
            r[a.Identificador] = r[a.Identificador] || {
              Identificador: a.Identificador,
              CanalAgente: a.CanalAgente,
              SubAgente: a.SubAgente,
              IdCliente: a.IdCliente,
              denominacion: a.denominacion,
              deudaFra: 0,
              deudaImp: 0,
              deudaApl: 0,
              deudaRec: 0,
              deuda: 0,
            };

            r[a.Identificador] = sumarDeuda(r[a.Identificador], a);

            return r;
          }, Object.create(null))
      )
        .filter(({ deuda }) => deuda > 0)
        .sort((a, b) => b.deuda - a.deuda);

      const deudaPorClienteMonitorio = Object.values(
        impApl
          .filter((c) =>
            [
              c.dptoJuridico,
              c.CESCE_siniestro,
              c.situacionContrato == "Baja",
            ].some((x) => x)
          )
          .reduce((r, a) => {
            r[a.Identificador] = r[a.Identificador] || {
              Identificador: a.Identificador,
              CanalAgente: a.CanalAgente,
              SubAgente: a.SubAgente,
              IdCliente: a.IdCliente,
              denominacion: a.denominacion,
              deudaFra: 0,
              deudaImp: 0,
              deudaApl: 0,
              deudaRec: 0,
              deuda: 0,
            };

            r[a.Identificador] = sumarDeuda(r[a.Identificador], a);

            if (a.Identificador == "B83304188") console.log(a);

            return r;
          }, Object.create(null))
      )
        .filter(({ deuda }) => deuda > 0)
        .sort((a, b) => b.deuda - a.deuda);

      const clientesComprometidosPago = Object.values(
        impApl
          .filter((imp) => imp.estadoPropio == "Comprometido Pago")
          .reduce((r, a) => {
            r[a.Identificador] = r[a.Identificador] || {
              Identificador: a.Identificador,
              CanalAgente: a.CanalAgente,
              SubAgente: a.SubAgente,
              IdCliente: a.IdCliente,
              denominacion: a.denominacion,
              deudaFra: 0,
              deudaImp: 0,
              deudaApl: 0,
              deudaRec: 0,
              deudaComprometida: 0,
            };

            r[a.Identificador] = sumarDeuda(r[a.Identificador], a);
            r[a.Identificador].deudaComprometida = r[a.Identificador].deuda;

            return r;
          }, Object.create(null))
      ).sort((a, b) => b.deudaComprometida - a.deudaComprometida);

      for (const cliComp of clientesComprometidosPago) {
        let item = [...deudaPorCliente, ...deudaPorClienteMonitorio].find(
          (cli) => cli.Identificador == cliComp.Identificador
        );
        if (item) cliComp.deudaTotal = item.deuda;
      }

      const deudaPorCanal = Object.values(
        impApl.reduce((r, a) => {
          r[a.CanalAgente] = r[a.CanalAgente] || {
            CanalAgente: a.CanalAgente,
            SubAgente: a.SubAgente,
            IdAgente: a.IdAgente,
            IdCliente: a.IdCliente,
            denominacion: a.denominacion,
            nImpagados: 0,
            deudaFra: 0,
            deudaImp: 0,
            deudaApl: 0,
            deudaRec: 0,
            deuda: 0,
          };

          r[a.CanalAgente] = sumarDeuda(r[a.CanalAgente], a);

          r[a.CanalAgente].nImpagados++;
          return r;
        }, Object.create(null))
      )
        .filter(({ deuda }) => deuda > 0)
        .sort((a, b) => b.deuda - a.deuda);

      const deudaPorPortal = Object.values(
        impApl.reduce((r, a) => {
          r[a.empresa] = r[a.empresa] || {
            portal: a.empresa,
            deudaFra: 0,
            deudaImp: 0,
            deudaApl: 0,
            deudaRec: 0,
            deuda: 0,
            nFacturas: 0,
          };
          r[a.empresa].nFacturas++;

          r[a.empresa] = sumarDeuda(r[a.empresa], a);

          return r;
        }, Object.create(null))
      )
        .filter(({ deuda }) => deuda > 0)
        .sort((a, b) => b.deuda - a.deuda);

      const clientesImpagadosPorCanal = Object.values(
        impApl
          .filter((imp) => !imp.futuro)
          .reduce((r, a) => {
            r[a.CanalAgente] = r[a.CanalAgente] || {
              CanalAgente: a.CanalAgente,
              totalClientes: a.totalClientesCanal,
              totalClientesImp: new Set(),
              porcentaje: 0,
            };
            r[a.CanalAgente].totalClientesImp.add(a.IdCliente);
            r[a.CanalAgente].porcentaje =
              parseFloat(
                (r[a.CanalAgente].totalClientesImp.size /
                  r[a.CanalAgente].totalClientes) *
                  100
              ).toFixed(2) * 1;
            return r;
          }, Object.create(null))
      ).sort((a, b) => b.porcentaje - a.porcentaje);

      const contratosBajaDeuda = Object.values(
        impApl
          .filter(
            (x) =>
              x.situacionContrato == "Baja" &&
              ((x.futuro && x.SerieFactura == "FRAC") ||
                x.estado == "Impagado" ||
                x.aplazado)
          )
          .reduce((r, a) => {
            r[a.CodigoContrato] = r[a.CodigoContrato] || {
              CodigoContrato: a.CodigoContrato,
              IdContrato: a.IdContrato,
              IdCliente: a.IdCliente,
              numeroImpagados: 0,
              denominacion: a.denominacion,
              deuda: 0,
            };
            r[a.CodigoContrato].numeroImpagados += 1;
            r[a.CodigoContrato].deuda += Number(a.ImporteTotal);

            return r;
          }, Object.create(null))
      ).sort((a, b) => (a.deuda > b.deuda ? -1 : 1));

      return {
        deudaPorCliente,
        deudaPorClienteMonitorio,
        deudaPorCanal,
        clientesImpagadosPorCanal,
        contratosBajaDeuda,
        deudaPorPortal,
        clientesComprometidosPago,
      };
    },
  },
  async mounted() {
    this.loading = true;
    await Promise.all([
      this.getImpagados(),
      this.getFacturas(),
      this.getListadoMensajes(),
    ]);

    this.populateTarjetas(this.impTab || 0);

    this.loading = false;
  },
  methods: {
    getFacturas() {
      return new Promise(async (resolve, reject) => {
        this.$set(this.checks, "facturas", {
          progress: "loading",
          texto: "Cargando facturas",
        });

        const get_estado = (CodigoSituacionCobro) => {
switch (Number(CodigoSituacionCobro)) {
              case -2:
                return "Gesico";
              case -3:
                return "Pérdidas";
              case 0:
                return "Sin Especificar";
              case 1:
                return "Pendiente";
              case 2:
                return "Cobrado";
              case 3:
                return "Impagado";
              case 4:
                return "Remesado";
              case 5:
                return "Carta Aviso";
              case 6:
                return "Carta Corte";
              case 7:
                return "Cortado";
              case 8:
                return "Cobrado norma 57";
              case 9:
                return "Cancelado por abono";
              case 10:
                return "Carta Corte 1";
              case 11:
                return "Carta Deuda";
              case 12:
                return "Fraccionado";
            }
        }

        const { data: facturas } = await axios({
          url: `${process.env.VUE_APP_OUR_API_URL}/facturacion.php`,
          method: "GET",
          params: {
            resumenImp: 1,
            quitarRemesadas: 0,
            token: this.$store.getters.getJwtEmpresa,
            codigoContrato: this.codigoContrato,
          },
        });
        // console.log(facturas.map(({ IdFacturaVentaCabecera, IdRemesaCobro, IdRemesaImpagadoCobro }) => ({ IdFacturaVentaCabecera, IdRemesaCobro, IdRemesaImpagadoCobro })))
        this.facturas = facturas
          .map((f) => {
            f.estado = get_estado(f.CodigoSituacionCobro)
            f.denominacion =
              f.RazonSocialTitular ||
              [f.Apellido1Titular, f.Apellido2Titular, f.NombreTitular]
                .filter((i) => !!i)
                .join(" ");

            f.futuro =
              f.estado == "Pendiente" ||
              parseDate(f.FechaComienzoRemesa, true) >= new Date().getTime();
            f.aplazado =
              f.estado == "Pendiente" &&
              parseDate(f.FechaComienzoRemesa, true) <= new Date().getTime();

            if (f.NFracciones && f.NumeroFraccion == null) return;
            if (f.estado == "Cancelado por abono" || f.estado == "Impagado")
              return;
            if (f.ImporteTotal <= 0) return;

            return f;
          })
          .filter((x) => !!x);

        this.checks.facturas.progress = true;

        resolve();
      });
    },
    exportar(v, tipo) {
      const csvHeaders = [
        {
          key: "Identificador",
          f: [
            "deudaPorCliente",
            "pendienteRemesarPorCliente",
            "contratosBajaDeuda",
            "clientesComprometidosPago",
          ],
        },
        {
          key: "CodigoContrato",
          f: [
            "deudaPorCliente",
            "pendienteRemesarPorCliente",
            "contratosBajaDeuda",
          ],
        },
        {
          key: "denominacion",
          f: [
            "deudaPorCliente",
            "pendienteRemesarPorCliente",
            "contratosBajaDeuda",
            "clientesComprometidosPago",
          ],
        },
        {
          key: "CanalAgente",
          f: [
            "deudaPorCliente",
            "pendienteRemesarPorCliente",
            "deudaPorCanal",
            "contratosBajaDeuda",
            "clientesImpagadosPorCanal",
            "clientesComprometidosPago",
          ],
        },
        {
          key: "SubAgente",
          f: [
            "deudaPorCliente",
            "pendienteRemesarPorCliente",
            "deudaPorCanal",
            "contratosBajaDeuda",
            "clientesImpagadosPorCanal",
            "clientesComprometidosPago",
          ],
        },
        { key: "numeroImpagados", type: "number", f: ["contratosBajaDeuda"] },
        {
          key: "deudaImp",
          alias: "Deuda Impagada",
          type: "number",
          f: ["deudaPorCliente", "deudaPorCanal"],
        },
        {
          key: "deudaApl",
          alias: "Giro Pendiente",
          type: "number",
          f: ["deudaPorCliente", "deudaPorCanal"],
        },
        {
          key: "deudaFra",
          alias: "Deuda Fraccionada",
          type: "number",
          f: ["deudaPorCliente", "deudaPorCanal"],
        },
        {
          key: "deuda",
          alias: "Deuda Total",
          type: "number",
          f: [
            "deudaPorCliente",
            "pendienteRemesarPorCliente",
            "deudaPorCanal",
            "contratosBajaDeuda",
          ],
        },
        {
          key: "deudaTotal",
          alias: "Deuda Total",
          type: "number",
          f: ["clientesComprometidosPago"],
        },
        {
          key: "deudaComprometida",
          alias: "Deuda comprometida",
          type: "number",
          f: ["clientesComprometidosPago"],
        },
        {
          key: "totalClientes",
          type: "number",
          f: ["clientesImpagadosPorCanal"],
        },
        {
          key: "totalClientesImp",
          type: "number",
          f: ["clientesImpagadosPorCanal"],
        },
      ].filter((h) => h.f.includes(tipo));

      const JStoExcelDate = (date) => {
        const [day, month, year] = date.split(" ");
        return [year, month, day].join("-");
      };

      const csvString = [
        ...v.map((item) => {
          let temp = [];
          csvHeaders.forEach(({ key, type }) => {
            let valor = String(item[key] || "\u0020").replace("\r\n", "");
            if (type == "date")
              valor = JStoExcelDate(parseDate(valor, false, false));
            if (type == "number") valor = valor.replace(".", ",");
            temp.push(valor);
          });
          return temp;
        }),
      ];

      // console.log(csvString)

      // const csv = new File([csvHeaders, ...csvString], `ESTADO_IMPAGADOS_${parseDate(new Date(), false, true).replace(' ', '_')}.csv`, {})

      const camelToSnakeCase = (str) =>
        str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

      const csvFile = new File(
        [
          "\ufeff",
          [csvHeaders.map((h) => h.alias || h.key), ...csvString]
            .map((e) => e.join(";"))
            .join("\n"),
        ],
        `${camelToSnakeCase(tipo).toUpperCase()}_${parseDate(
          new Date(),
          false,
          true
        )}.csv`,
        { type: "text/csv;charset=utf-8;" }
      );

      downloadFile(csvFile);
    },
    getImpagados() {
      return new Promise(async (resolve, reject) => {
        this.$set(this.checks, "impagados", {
          progress: "loading",
          texto: "Cargando impagados",
        });
        this.porcentajes = {};

        const impagados = await Promise.all([
          axios({
            url: `${process.env.VUE_APP_OUR_API_URL}/resumen_impagos/resumen.php`,
            method: "GET",
            params: {
              token: this.$store.getters.getJwtEmpresa,
            },
          }),
          // axios({ url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`, method: 'GET', params: { token: this.$store.getters.getJwtEmpresa, menuTab: 'actual' } }),
          // axios({ url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`, method: 'GET', params: { token: this.$store.getters.getJwtEmpresa, menuTab: 'cesce' } }),
          // axios({ url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`, method: 'GET', params: { token: this.$store.getters.getJwtEmpresa, menuTab: 'monitorio' } }),
          // axios({ url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`, method: 'GET', params: { token: this.$store.getters.getJwtEmpresa, menuTab: 'historico' } }),
          // axios({ url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`, method: 'GET', params: { token: this.$store.getters.getJwtEmpresa, menuTab: 'fracTransferencia' } }),
        ]);

        this.impagados = parseImpagados(impagados.map((i) => i.data).flat());

        // for (let index = 0; index < imp.length; index++) {
        //   const el = imp[index];
        //   if (
        //     this.impagados.findIndex(
        //       (i) => i.IdCarteraCobro == el.IdCarteraCobro
        //     ) == -1
        //   )
        //     this.impagados.push(el);
        // }
        // .filter(f => (f.NFracciones == null && f.NumeroFraccion == null) || (f.NFracciones != null && f.NumeroFraccion > 0))

        this.checks.impagados.progress = true;

        resolve();
      });
    },
    getListadoMensajes() {
      return new Promise(async (resolve, reject) => {
        this.$set(this.checks, "mensajes", {
          progress: "loading",
          texto: "Cargando últimos mensajes",
        });

        const { data } = await axios({
          url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
          method: "GET",
          params: {
            token: this.$store.getters.getJwtEmpresa,
          },
        });
        this.listadoMensajes = data.map((f) => {
          f.denominacion =
            f.RazonSocialTitular ||
            [f.Apellido1Titular, f.Apellido2Titular, f.NombreTitular]
              .filter((i) => !!i)
              .join(" ");

          return f;
        });

        this.checks.mensajes.progress = true;
        resolve();
      });
    },
    verPDFfactura(idDocumento) {
      this.pdfId = idDocumento;
      let str = this.$store.getters.getDatosEmpresa;
      const { IdUsuario } = str.iss;
      const IdEmpresa = this.$store.getters.getIdEmpresa;
      axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}/FacturaVentaCabecera/FacturaPreviewPDF/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Documento/${idDocumento}`,
        responseType: "blob",
      })
        .then((res) => {
          let pdf = new File(
            [res.data],
            `factura_${new Date().getTime()}.pdf`,
            {
              type: "application/pdf",
            }
          );
          downloadFile(pdf, true);
          this.pdfId = null;
        })
        .catch((err) => {
          this.$root.$emit("snack", "Ha ocurrido un error");
          this.pdfId = null;
        });
    },
    parseDate,
    populateTarjetas() {
      if (!this.facturas || this.facturas.length == 0) return;

      const filtroPeriodo = (fecha) => parseDate(fecha, true) <= this.periodo;
      const sumarImportes = (arr) => parseFloat(arr.reduce((t, c) => Number(c.ImporteTotal) + t, 0)).toFixed(2) * 1;
      const countUnique = (iterable) => new Set(iterable).size;

      const impAhora = this.impagados.filter( (imp) => !imp.futuro );
      const facAhora = [
        ...this.facturas,
        ...this.impagados.filter((imp) => imp.futuro == true),
      ];

      const clientesConImpagos = (impAhora.map((imp) => imp.IdCliente * 1 ));
      const gestionPropia = impAhora.filter((imp) => !imp.CESCE_siniestro && !imp.envio_gesico && !imp.dptoJuridico && imp.estado == 'Impagado' );
      const gestionGesico = impAhora.filter((imp) => imp.envio_gesico && (imp.estado == 'Impagado' || imp.estado == 'Pendiente'));
      const gestionMonitorio = impAhora.filter((imp) => imp.dptoJuridico && (imp.estado == 'Impagado' || imp.estado == 'Pendiente'));
      const gestionCesce = impAhora.filter((imp) => imp.CESCE_siniestro && (imp.estado == 'Impagado' || imp.estado == 'Pendiente'));
      const estadoPerdidas = impAhora.filter((imp) => imp.estado == "Pérdidas");
      const pendienteRemesar = facAhora.filter(
        (imp) =>
          imp.SerieFactura != "FRAC" &&
          imp.estado == "Pendiente" &&
          imp.IdTipoCobro == 2 &&
          imp.ImporteTotal > 0
      );

      this.tarjetasTop.clientesConImpagos.value = countUnique(clientesConImpagos)
      this.tarjetasTop.gestionPropia.value = sumarImportes(gestionPropia)
      this.tarjetasTop.gestionGesico.value = sumarImportes(gestionGesico)
      this.tarjetasTop.gestionMonitorio.value = sumarImportes(gestionMonitorio)
      this.tarjetasTop.gestionCesce.value = sumarImportes(gestionCesce)
      this.tarjetasTop.estadoPerdidas.value = sumarImportes(estadoPerdidas)
      this.tarjetasTop.pendienteRemesar.value = sumarImportes(pendienteRemesar)

      this.tarjetasTop.totalImpagadosTotal.value = parseFloat(
        this.tarjetasTop.gestionPropia.value +
        this.tarjetasTop.gestionGesico.value +
        this.tarjetasTop.gestionMonitorio.value +
        this.tarjetasTop.gestionCesce.value ).toFixed(2) * 1

    },
    // populateTarjetas(historico) {
    //   if (!this.facturas || this.facturas.length == 0) return;

    //   const filtroPeriodo = (fecha) => parseDate(fecha, true) <= this.periodo

    //   const impAhora = this.impagados.filter(imp => !imp.perdida && imp.historico == historico && !imp.futuro);
    //   const impPeriodoAnterior = impAhora.filter(imp => filtroPeriodo(historico ? (imp.FechaCobro || imp.FechaCargo || imp.FechaComienzoRemesa) : imp.FechaImpagado))

    //   const facAhora = [...this.facturas, ...this.impagados.filter(imp => !imp.perdida && imp.futuro == true)];
    //   const facPeriodoAnterior = facAhora.filter(fac => filtroPeriodo(fac.FechaFactura));

    //   const perAhora = this.impagados.filter(imp => imp.perdida && imp.historico == historico );
    //   const perPeriodoAnterior = perAhora.filter(imp => filtroPeriodo(historico ? (imp.FechaCobro || imp.FechaCargo || imp.FechaComienzoRemesa) : imp.FechaImpagado))

    //   // const impFiltrados = impAhora.filter(imp => imp.historico == historico);
    //   const sumarImportes = (arr) => parseFloat(arr.reduce((t, c) => Number(c.ImporteTotal) + t, 0)).toFixed(2) * 1;
    //   const diffPorcentajes = (a, b) => parseFloat((a - b) / a * 100).toFixed(2) * 1

    //   // NUMERO IMPAGADOS
    //   this.tarjetasTop.numeroImpagados.value = impAhora.length
    //   this.tarjetasTop.numeroImpagados.increment = diffPorcentajes(impAhora.length, impPeriodoAnterior.length);
    //   this.tarjetasTop.numeroImpagados.incrementWay = historico ? 'up' : 'down'

    //   // CLIENTES IMPAGADOS
    //   const clientesImpagadosAhora = [...new Set([...impAhora, ...facAhora.filter(f => f.aplazado || (f.futuro && f.SerieFactura == 'FRAC'))].map(imp => imp.IdCliente * 1))].length;
    //   const clientesImpagadosPeriodoAnterior = [...new Set([...impPeriodoAnterior, ...facPeriodoAnterior.filter(f => f.aplazado)].map(imp => imp.IdCliente * 1))].length
    //   this.tarjetasTop.clientesImpagados.value = clientesImpagadosAhora
    //   this.tarjetasTop.clientesImpagados.increment = diffPorcentajes(clientesImpagadosAhora, clientesImpagadosPeriodoAnterior);
    //   this.tarjetasTop.clientesImpagados.incrementWay = historico ? 'up' : 'down'

    //   // PAGO NO ACORDADO
    //   const noAcordadoAhora = impAhora.filter(imp => imp.estadoPropio != 'Comprometido Pago');
    //   const noAcordadoPeriodoAnterior = impPeriodoAnterior.filter(imp => imp.estadoPropio != 'Comprometido Pago' && filtroPeriodo(imp.estadoPropioFecha));
    //   this.tarjetasTop.pagoNoAcordado.value = sumarImportes(noAcordadoAhora)
    //   this.tarjetasTop.pagoNoAcordado.increment = diffPorcentajes(this.tarjetasTop.pagoNoAcordado.value, sumarImportes(noAcordadoPeriodoAnterior));

    //   // PAGO COMPROMETIDO
    //   const comprometidoAhora = impAhora.filter(imp => imp.estadoPropio == 'Comprometido Pago');
    //   const comprometidoPeriodoAnterior = impPeriodoAnterior.filter(imp => imp.estadoPropio == 'Comprometido Pago' && filtroPeriodo(imp.estadoPropioFecha));
    //   this.tarjetasTop.totalComprometidoPago.value = sumarImportes(comprometidoAhora)
    //   this.tarjetasTop.totalComprometidoPago.increment = diffPorcentajes(this.tarjetasTop.totalComprometidoPago.value, sumarImportes(comprometidoPeriodoAnterior));

    //   // PERDIDAS
    //   // const perdidasAhora = impAhora.filter(imp => imp.estadoPropio == 'Comprometido Pago');
    //   // const comprometidoPeriodoAnterior = impPeriodoAnterior.filter(imp => imp.estadoPropio == 'Comprometido Pago' && filtroPeriodo(imp.estadoPropioFecha));
    //   this.tarjetasTop.totalPerdidas.value = sumarImportes(perAhora)
    //   this.tarjetasTop.totalPerdidas.increment = 0;
    //   // this.tarjetasTop.totalComprometidoPago.increment = diffPorcentajes(this.tarjetasTop.totalComprometidoPago.value, sumarImportes(comprometidoPeriodoAnterior));

    //   // PENDIENTE REMESAR
    //   // const pendienteRemesarAhora = [...impAhora, ...facAhora].filter(imp => imp.aplazado || (false && imp.futuro && imp.SerieFactura == 'FRAC'));
    //   const pendienteRemesarAhora = facAhora.filter(imp => imp.SerieFactura != 'FRAC' && imp.estado == 'Pendiente' && imp.IdTipoCobro == 2 && imp.ImporteTotal > 0 );
    //   const pendienteRemesarPeriodoAnterior = facPeriodoAnterior.filter(imp => imp.SerieFactura != 'FRAC' && imp.estado == 'Pendiente' && imp.IdTipoCobro == 2 && imp.ImporteTotal > 0);
    //   this.tarjetasTop.pendienteRemesar.value = sumarImportes(pendienteRemesarAhora)
    //   this.tarjetasTop.pendienteRemesar.increment = diffPorcentajes(this.tarjetasTop.pendienteRemesar.value, sumarImportes(pendienteRemesarPeriodoAnterior));

    //   // TOTAL IMPAGADOS
    //   const totalAhora = parseFloat(this.tarjetasTop.pagoNoAcordado.value + this.tarjetasTop.totalComprometidoPago.value + this.tarjetasTop.pendienteRemesar.value).toFixed(2) * 1
    //   const totalPeriodoAnterior = parseFloat((this.tarjetasTop.pagoNoAcordado.value * ((100 - this.tarjetasTop.pagoNoAcordado.increment) / 100)) + (this.tarjetasTop.totalComprometidoPago.value * ((100 - this.tarjetasTop.totalComprometidoPago.increment) / 100)) + (this.tarjetasTop.pendienteRemesar.value * ((100 - this.tarjetasTop.pendienteRemesar.increment) / 100))).toFixed(2) * 1
    //   this.tarjetasTop.totalImpagadosTotal.value = totalAhora * 1;
    //   this.tarjetasTop.totalImpagadosTotal.increment = diffPorcentajes(totalAhora, totalPeriodoAnterior);

    //   // const aux =
    //   // {
    //   //   total: sumarImportes([...impAhora, ...facAhora.filter(f => f.aplazado || f.futuro)]),
    //   //   comprometido: sumarImportes(impAhora.filter(f => f.estadoPropio == 'Comprometido Pago')),
    //   //   noComprometido: sumarImportes(impAhora.filter(f => f.estadoPropio != 'Comprometido Pago')),
    //   //   pendienteRemesar: sumarImportes([...impAhora, ...facAhora].filter(imp => imp.aplazado || imp.futuro))
    //   // }

    //   // TOTAL RECUPERADO
    //   if (historico) {
    //     const totalRecuperadoAhora = impAhora.filter(imp => !imp.futuro);
    //     const totalRecuperadoPeriodoAnterior = impPeriodoAnterior.filter(imp => filtroPeriodo(imp.FechaCobro || imp.FechaCargo || imp.FechaComienzoRemesa));
    //     this.tarjetasTop.totalRecuperado.value = sumarImportes(totalRecuperadoAhora)
    //     this.tarjetasTop.totalRecuperado.increment = diffPorcentajes(this.tarjetasTop.totalRecuperado.value, sumarImportes(totalRecuperadoPeriodoAnterior));
    //   }

    //   // this.tarjetasTop.abonosPendientes.value = Math.abs(sumarImportes([...this.impagados, ...this.facturas].filter(imp => imp.futuro && imp.ImporteTotal < 0)))

    //   const clientesBajaAhora = Object.values(impAhora.reduce((r, a) => {
    //     r[a.Identificador] = r[a.Identificador] || { deudas: false }
    //     r[a.Identificador].deudas = r[a.Identificador].deudas || a.situacionContrato == 'Baja'
    //     return r;
    //   }, Object.create(null)))
    //   const clientesBajaPeriodoAnterior = Object.values(impPeriodoAnterior.reduce((r, a) => {
    //     r[a.Identificador] = r[a.Identificador] || { deudas: false }
    //     r[a.Identificador].deudas = r[a.Identificador].deudas || a.situacionContrato == 'Baja'
    //     return r;
    //   }, Object.create(null)))

    //   this.tarjetasTop.totalClientesBajaDeuda.value = clientesBajaAhora.filter(c => c.deudas).length;
    //   this.tarjetasTop.totalClientesBajaDeuda.increment = diffPorcentajes(this.tarjetasTop.totalClientesBajaDeuda.value, clientesBajaPeriodoAnterior.filter(c => c.deudas).length);

    //   Object.entries(this.porcentajes).forEach(([key, value]) => {
    //     this.porcentajes[key] = value * 100 / this.impagados.length;
    //   })
    // }
  },
  watch: {
    impTab: {
      handler(historico) {
        this.populateTarjetas(historico);
      },
      immediate: true,
    },
    periodo: {
      handler() {
        this.populateTarjetas(this.impTab);
      },
      immediate: true,
      deep: true,
    },
    loading(val) {
      if (val) this.checksPanel = true;
      else {
        setTimeout(() => {
          this.checksPanel = false;
          this.checks = {};
        }, 1000);
      }
    },
  },
};
</script>
